// ============================
//     dropdown css start
// ============================

.dropdown-toggle {
  &.arrow-none {
    &:after {
      display: none;
    }
  }
}

.pc-header {
  .dropdown-menu {
    animation: 0.3s ease-in-out 0s normal forwards 0.3s fadein;
  }
}
@keyframes fadein {
  from {
    transform: translate3d(0, 8px, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.dropdown .dropdown-item {
  display: flex;
  align-items: center;

  &.active,
  &:active,
  &:focus,
  &:hover {
    background: var(--pc-active-background);
    color: var(--bs-dropdown-link-color);
    i {
      &.material-icons-two-tone {
        background-color: $dropdown-link-hover-color;
      }
    }
  }
}

.dropdown-menu {
  box-shadow: 0 4px 24px 0 rgba(62, 57, 107, 0.18);
  border: none;
  padding: 8px;
  .dropdown-item {
    border-radius: 8px;
    padding: 10px 15px;
    i {
      font-size: 18px;
      margin-right: 10px;

      &.material-icons-two-tone {
        vertical-align: bottom;
        font-size: 22px;
        background-color: var(--pc-header-color);
      }
    }

    svg {
      width: 18px;
      height: 18px;
      margin-right: 10px;
      fill: #f2f2f2;
    }

    .float-right {
      svg {
        width: 14px;
        height: 14px;
      }
    }
  }
}
// dropdown css end
