/**  =====================
      Switches css start
==========================  **/
.switch{
  margin-bottom: 8px;
  margin-right: 8px;
}
.slow .switch-group {
  transition: left 0.7s;
  -webkit-transition: left 0.7s;
}

.fast .switch-group {
  transition: left 0.1s;
  -webkit-transition: left 0.1s;
}

.quick .switch-group {
  transition: none;
  -webkit-transition: none;
}

.table.switch-table {
  th,
  td {
    vertical-align: middle;
    padding: 0.45rem 0.25rem;

    .btn {
      margin-bottom: 0;
    }
  }
}
/**====== Switches css end ======**/
