/**  =====================
      Chat css start
==========================  **/
.chat-wrapper {
  display: flex;

  .chat-user_list {
    width: 300px;
    margin-right: var(--bs-gutter-x);

    .scroll-block {
      height: calc(100vh - 520px);
    }
  }

  .chat-content {
    flex: 1 1 auto;
  }

  .chat-user_info {
    width: 300px;
    margin-left: var(--bs-gutter-x);

    .scroll-block {
      height: calc(100vh - 470px);
    }

    .chat-avtar {
      img {
        border: 1px solid var(--bs-success);
        padding: 3px;
      }

      .chat-badge {
        bottom: 8px;
        right: 8px;
        width: 8px;
        height: 8px;
      }
    }
  }

  // .offcanvas {
  // }
  .chat-offcanvas {
    --bs-offcanvas-width: 301px;

    &.show {
      .collapse:not(.show) {
        display: block;
      }

      .chat-user_list {
        margin-right: 0;

        .scroll-block {
          height: calc(100vh - 215px);
        }
      }

      .chat-user_info {
        margin-left: 0;

        .scroll-block {
          height: calc(100vh - 245px);
        }
      }
    }
  }
}

.chat-badge {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  color: #fff;
  overflow: hidden;
}

.chat-badge-status {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.chat-avtar {
  position: relative;

  .chat-badge {
    position: absolute;
    top: 1px;
    right: 1px;
  }
}

.chat-message {
  &.scroll-block {
    height: calc(100vh - 390px);
  }

  .msg-content {
    padding: 12px 16px;
    border-radius: var(--bs-border-radius);
  }

  .message-out,
  .message-in {
    display: flex;
    margin-bottom: 24px;

    > .d-flex {
      max-width: 90%;
      @include media-breakpoint-down(md) {
        max-width: 100%;
      }
    }
    .chat-msg {
      .chat-msg-option {
        opacity: 0;
      }
      &:hover {
        .chat-msg-option {
          opacity: 1;
        }
      }
    }
  }

  .message-out {
    justify-content: flex-end;

    p {
      color: #fff;
    }

    p:last-child {
      text-align: right;
    }
    .chat-msg {
      &:not(:first-child) {
        .msg-content {
          border-top-right-radius: 0;
        }
      }
      &:not(:last-child) {
        .msg-content {
          border-bottom-right-radius: 0;
        }
      }
    }
  }

  .message-in {
    justify-content: flex-start;
    .chat-msg {
      &:not(:first-child) {
        .msg-content {
          border-top-left-radius: 0;
        }
      }
      &:not(:last-child) {
        .msg-content {
          border-bottom-left-radius: 0;
        }
      }
    }
  }
}

// Chat css end
